export const HOME_PAGE = '/';
export const ACCOUNTS_PAGE = '/accounts';

export const ACCOUNTS_DRAFTS = '/accounts/drafts';
export const ACCOUNTS_IMPORTANTDATES = '/accounts/important-dates';
export const ACCOUNTS_ACTIVE = '/accounts/active';
export const ACCOUNTS_INACTIVE = '/accounts/inactive';

export const ACCOUNTS_EDIT = (accountId = ':accountId') => `/accounts/${accountId}/edit`;
export const ACCOUNTS_COMPLETE = (accountId = ':accountId') => `/accounts/${accountId}/complete/check`;
export const ACCOUNTS_DETAIL = (accountId = ':accountId') => `/accounts/${accountId}/detail`;
export const ACCOUNTS_DETAIL_HISTORY = (accountId = ':accountId') => `/accounts/${accountId}/detail/history`;
export const ACCOUNTS_DETAIL_REPORTS = (accountId = ':accountId') => `/accounts/${accountId}/detail/statements`;
export const ACCOUNTS_DETAIL_ID = (accountId = ':accountId') => `/accounts/${accountId}/detail/investor-questionnaire`;
export const ACCOUNTS_DETAIL_PLAN = (accountId = ':accountId') => `/accounts/${accountId}/detail/plan`;
export const ACCOUNTS_DETAIL_DOCUMENTS = (accountId = ':accountId') => `/accounts/${accountId}/detail/documents`;
export const ACCOUNTS_DETAIL_FEES = (accountId = ':accountId') => `/accounts/${accountId}/detail/fees`;
export const ACCOUNTS_DETAIL_MOVEMENTS = (accountId = ':accountId') => `/accounts/${accountId}/detail/movements`;
export const ACCOUNTS_DETAIL_PERSONAL = (accountId = ':accountId') => `/accounts/${accountId}/detail/personal`;
export const ACCOUNTS_NOTES = (accountId = ':accountId') => `/accounts/${accountId}/notes`;

export const ACCOUNTS_DOCUMENTS = (accountId = ':accountId') => `/accounts/${accountId}/documents`;
export const ACCOUNTS_FEES_EDIT = (accountId = ':accountId') => `/accounts/${accountId}/edit/fees`;

export const PDF_MICROSITE = '/pdf';

export const ADVISOR_RANKINGS = '/advisor/rankings';

export const ADMIN = '/admin';
export const ADMIN_CONSTANTS = '/admin/constants';
export const ADMIN_IMPORT = '/admin/import';
export const ADMIN_PORTFOLIOS = '/admin/portfolios';
export const ADMIN_INSTRUMENTS = '/admin/instruments';

export const BACK_OFFICE = '/backoffice';
export const BO_CSV = '/backoffice/csv';
export const BO_IMPORT = '/backoffice/import';
export const BO_TOOLS = '/backoffice/tools';
export const BO_NOTIFICATIONS = '/backoffice/banner';
export const BO_SIGNED_DOCUMENTS = '/backoffice/documents';
export const BO_ADVISORS = '/backoffice/advisors';

export const CLIENT_ACCOUNT_DETAIL_DASHBOARD = (vs = ':vs') => `/${vs}/dashboard`;
export const CLIENT_ACCOUNT_DETAIL_HISTORY = (vs = ':vs') => `/${vs}/history`;
export const CLIENT_ACCOUNT_DETAIL_REPORTS = (vs = ':vs') => `/${vs}/statements`;
export const CLIENT_ACCOUNT_DETAIL_ID = (vs = ':vs') => `/${vs}/investor-questionnaire`;
export const CLIENT_ACCOUNT_DETAIL_PLAN = (vs = ':vs') => `/${vs}/plan`;
export const CLIENT_ACCOUNT_DETAIL_DOCUMENTS = (vs = ':vs') => `/${vs}/documents`;
export const CLIENT_ACCOUNT_DETAIL_SIGNATURE = (vs = ':vs') => `/${vs}/signature`;

export const CLIENT_ERROR = '/error';
